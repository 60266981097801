import React from 'react'
import Alerta from '../../../../src/common/assets/home/icon_home_alerta.svg'
import Calendario from '../../../../src/common/assets/home/icon_home_calendario.svg'
import Confianza from '../../../../src/common/assets/home/icon_home_confianza.svg'
import Neumatico from '../../../../src/common/assets/home/icon_home_neumatico.svg'
import Taller from '../../../../src/common/assets/home/icon_home_taller.svg'

const valoresText = {
  neumatico_es: (
    <p>
      El <strong>mejor precio</strong> de <strong>neumáticos</strong> coche
      camión y guaguas
    </p>
  ),
  calendario_es: (
    <p>
      Pide tu <strong>cita previa</strong>
      <br></br> con un click
    </p>
  ),
  taller_es: (
    <p>
      Talleres líderes en <strong>Canarias</strong>
    </p>
  ),
  alerta_es: (
    <p>
      Servicios de <strong>mantenimiento</strong> personalizados
    </p>
  ),
  confianza_es: (
    <p>
      Mecánicos <strong>profesionales</strong> de <strong>confianza</strong>
    </p>
  ),
}

const valores = [
  {
    icon: <Neumatico />,
    text_es: valoresText.neumatico_es,
    link: 'neumaticos',
  },
  {
    icon: <Calendario />,
    text_es: valoresText.calendario_es,
    link: 'cita-previa.seleccion',
  },
  {
    icon: <Taller />,
    text_es: valoresText.taller_es,
    link: 'talleres',
  },
  {
    icon: <Alerta />,
    text_es: valoresText.alerta_es,
    link: 'register',
  },
  {
    icon: <Confianza />,
    text_es: valoresText.confianza_es,
    link: 'contacto',
  },
]

export default valores
