import Michelin from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/michelin.svg'
import Pirelli from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/pirelli.svg'
import Continental from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/continental.svg'
import Goodyear from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/goodyear.svg'
import Dunlop from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/dunlop.svg'
import Bridgestone from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bridgestone.svg'
import Vredestein from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/vredestein.svg'
import Falken from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/falken.svg'
import BfGoodrich from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bfgoodrich.svg'
import Maxxis from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/maxxis.svg'
import Uniroyal from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/uniroyal.svg'
import Roadx from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/roadx.svg'
import Tigar from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/tigar.svg'
import Fortune from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/fortune.svg'

const logos = [
  {
    marca: 'michelin',
    src: Michelin,
  },
  {
    marca: 'continental',
    src: Continental,
  },
  {
    marca: 'dunlop',
    src: Dunlop,
  },
  {
    marca: 'falken',
    src: Falken,
  },
  {
    marca: 'bfgoodrich',
    src: BfGoodrich,
  },
  {
    marca: 'roadx',
    src: Roadx,
  },
  {
    marca: 'tigar',
    src: Tigar,
  },
  {
    marca: 'pirelli',
    src: Pirelli,
  },
  {
    marca: 'bridgestone',
    src: Bridgestone,
  },
  {
    marca: 'goodyear',
    src: Goodyear,
  },
  {
    marca: 'maxxis',
    src: Maxxis,
  },
  {
    marca: 'vredestein',
    src: Vredestein,
  },
  {
    marca: 'uniroyal',
    src: Uniroyal,
  },
  {
    marca: 'fortune',
    src: Fortune,
  },
]

export default logos
